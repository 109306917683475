<!--
  this script is used to create the footer HTML snippet
  @since ML (xx.06.2020)
-->
<section id="{{slug}}" class="container">
  <div id="{{slug}}-container" class="row mb-2 mt-1 mt-md-2 d-flex">
    <div class="col-md-4 mb-2 mt-2 text-md-left text-center order-2 order-md-1">
      <a href="{{dataService.company_url}}" target="_blank">{{dataService.domain}}</a>
    </div>
    <div class="col-md-4 mb-2 mt-2 text-md-center text-center order-1 order-md-2">
      <img src="{{ imagesOthers }}logo.svg" class="footer-img">
    </div>
    <div class="col-md-4 mb-2 mt-2 text-md-right text-center order-3 order-md-3">
      <a href="imprint" target="_blank">{{theo('imprint')}}</a>
      <span> | </span>
      <a href="data-privacy" target="_blank">{{theo('data_privacy')}}</a>
    </div>
  </div>
</section>
