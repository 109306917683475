import { Injectable } from '@angular/core';
import {LanguageService} from './language.service';

@Injectable({
    providedIn: 'root',
})

/**
 * PM (21.06.219)
 * this service is used as proxy to avoid errors during the initialisation before the values are fetched from the server
 */
export class DataService {
  static readonly devFlag = 'srv05.24743.serviceprovider.de'
  static readonly domain = 'sigmasport.com'
  static readonly host = window.location.hostname
  static readonly devMode = !window.location.hostname.includes(DataService.domain.split('.')[0]) || window.location.href.includes(DataService.devFlag) // TRUE if local || staging
  static readonly devModeML = DataService.devMode && window.location.href.includes('liebmann.')
  static readonly devModePM = DataService.devMode && window.location.href.includes('meppe.')

  static readonly company_url = 'https://' + DataService.domain + (window.location.href.includes(DataService.devFlag) ? '.' + DataService.devFlag : '')
  static readonly company = 'SIGMA SPORT'
  static readonly copyright = `&copy; ${DataService.company} ${new Date().getFullYear()}`
}
