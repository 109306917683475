import {Thesaurus} from './thesaurus';
import {DataService} from '../utils/data.service';
import {Unit} from '../utils/units';
import {Paragraph} from '../utils/interfaces';

export class ThesaurusEN extends Thesaurus {
  //sport disciplines
  sport = 'sport discipline'
  racing_bycicle = 'road bike'
  mountainbike = 'mountain bike'
  cycling = 'cycling'
  running = 'running'
  walking = 'walking'
  hiking = 'hiking'
  treadmill = 'treadmill'
  fitness = 'fitness'
  swimming = 'swimming'
  indoor_cycling = 'indoor cycling'
  golf = 'golf'
  inline_skates = 'inline skates'
  ski = 'ski'
  snowboarding = 'snowboarding'
  other = 'other '+ this.sport
  triathlon = 'triathlon'
  ebike = 'e-bike'
  bmx = 'BMX'
  cyclecross = 'cycle cross'
  enduro = 'enduro'
  //...
  activity = 'activity'
  tracker = 'tracker'
  route = 'route'

  //date
  date_mon = 'mon'
  date_monday = 'monday'
  date_tue = 'tue'
  date_tuesday = 'tuesday'
  date_wed = 'wed'
  date_wednesday = 'wednesday'
  date_thu = 'thu'
  date_thursday = 'thursday'
  date_fri = 'fri'
  date_friday = 'friday'
  date_sat = 'sat'
  date_saturday = 'saturday'
  date_sun = 'sun'
  date_sunday = 'sunday'
  //...
  date_jan = 'jan'
  date_january = 'january'
  date_feb = 'feb'
  date_february = 'february'
  date_mar = 'mar'
  date_march = 'march'
  date_apr = 'apr'
  date_april = 'april'
  date_may = 'may'
  date_jun = 'jun'
  date_june = 'june'
  date_jul = 'jul'
  date_july = 'july'
  date_aug = 'aug'
  date_august = 'august'
  date_sep = 'sep'
  date_september = 'september'
  date_oct = 'oct'
  date_october = 'october'
  date_nov = 'nov'
  date_november = 'november'
  date_dec = 'dec'
  date_december = 'december'

  //resources preliminary
  average = 'AVG.'
  downhill = 'DOWNHILL'
  maximum = 'MAX.'
  max = 'MAX.'
  minimum = 'MIN.'
  mode = 'MODE'
  uphill = 'UPHILL'

  //resources
  activity_tracker = 'activity tracker'
  altitude = 'altitude'
  altitude_downhill = this.brackets(this.altitude, this.downhill)
  altitude_uphill = this.brackets(this.altitude, this.uphill)
  available = 'available'
  average_downhill = this.brackets(this.average, this.downhill)
  average_percent = this.brackets(this.average, Unit.pct)
  average_uphill = this.brackets(this.average, this.uphill)
  average_watt_per_kg = this.brackets(this.average, `${Unit.watt}/${Unit.kg}`)
  assist = 'assist mode'
  assist_mode = this.assist
  balance = 'balance'
  battery = 'battery'
  cadence = 'cadence'
  calories = 'calories'
  categories = 'categories'
  categories_flat = 'flat'
  categories_hilly = 'hilly'
  categories_mountainous = 'mountainous'
  categories_none = 'no categories'
  charts = 'charts'
  charts_none = 'no charts data available'
  close = 'close'
  differences_downhill = 'DOWNHILL'
  differences_uphill = 'UPHILL'
  distance = 'distance'
  distance_value = this.distance
  download = 'download'
  download_start = 'start download'
  foot = this.brackets('foot', Unit.ft)
  fullscreen = 'Fullscreen'
  gps_none = 'no GPS data available'
  heart_rate = 'heart rate'
  heart_rate_average = this.brackets(this.heart_rate, this.average)
  hide = 'hide'
  kilogram = this.brackets('kilogram', Unit.kg)
  lap = 'lap'
  laps = 'laps'
  laps_auto = 'automatic '+ this.laps
  laps_reg = this.laps
  level_last = 'Level'
  map_data = 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
  map_notice_desktop = 'Double click to activate the map.'
  map_notice_mobile = 'Press & hold to activate the map.'
  miles = this.brackets('miles', Unit.mi)
  mode_0 = 'off'
  mode_1 = 'Mode 1'
  mode_2 = 'Mode 2'
  mode_3 = 'Mode 3'
  mode_4 = 'Mode 4'
  mode_5 = 'Mode 5'
  mode_6 = 'Mode 6'
  mode_7 = 'Mode 7'
  mode_8 = 'Mode 8'
  mode_9 = 'Mode 9'
  none = 'no disciplines'
  pound = this.brackets('pound', Unit.lb)
  power = 'power'
  rating = 'rating'
  rating_star = 'star'
  rating_stars = 'stars'
  select_language = 'select a language'
  select_files = 'select the file formats'
  settings = 'settings'
  show = 'show'
  speed = 'speed'
  speed_average = this.speed
  steps = 'steps'
  steps_remaining = 'steps remaining'
  suitable_for = 'suitable for'
  temperature = 'temperature'
  time = 'time'
  time_absolute = this.time
  time_active = this.brackets(this.time, 'active')
  time_low = this.brackets(this.time, 'low')
  time_high = this.brackets(this.time, 'high')
  time_middle = this.brackets(this.time, 'middle')
  time_value = 'Lap '+ this.time
  time_training = this.brackets(this.time, 'training')
  times = this.time_active
  weight = 'weight'

  //others
  cookies_admin_accept = 'Agreed'
  cookies_admin_content = `To improve the functionality of our site we use cookies. By using the page you agree to the use of cookies. <a href="${window.location.origin}/data-privacy" target="_blank">More information</a>`
  cookies_admin_title = 'Cookies notice'
  data_privacy = 'data privacy'
  imprint = 'imprint'
  theme = 'theme'
  zyxw = 'ZYXW'


  //##### complex properties
  errors = {
    configuration_invalid: 'The configuration is invalid.',
    configuration_missing: 'The configuration is missing.' ,
    state_unknown: 'The resource state is unknown.',
    identifier_invalid: 'The identifier is invalid.',
    identifier_empty: 'The identifier is empty.',
    ip_address_invalid: 'Your IP address is invalid.',
  };
  chart_toolbar = {
    exportToSVG: 'Download SVG',
    exportToPNG: 'Download PNG',
    menu: 'Menu',
    selection: 'Selection',
    selectionZoom: 'Selection Zoom',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    pan: 'Panning',
    reset: 'Reset Zoom'
  }
  data_privacy_paragraphs: Paragraph[] = [
    {
      content: [
        'The security of your data and a better transparency are very important to us.',
        'In consideration of the European data protection law - EU General Data Protection Regulation (GDPR) - we are currently meeting our obligations concerning transparency, in detail the storage and use of your data.',
        `In this privacy policy we shall inform you on what type of personal data we collect in the context of your use of <a href="${window.location.origin}">${window.location.host}</a> and how and for what purpose the data is used by us and what are your rights in this respect. You can call up this information at any time at <a href="${window.location.href}">${window.location.host + window.location.pathname}</a>.`
      ]
    },
    {
      primary: 'Summary',
      content: [
        `<ul>
          <li>We store <strong>web server log files</strong>.</li>
          <li>We pass on your data only for the purpose of prosecution of crimes or for asserting own claims <strong>to third parties</strong> or if we are legally obliged to do so.</li>
          <li>We use <strong>Matomo</strong>(<strong>formerly</strong> <strong>Piwik</strong>), a software with which we collect statistics on the use of SIGMA-ELEKTRO GmbH.</li>
        </ul>`
      ]
    },
    {
      primary: 'Legal basis for the processing of personal data',
      content: [
        'Insofar as we obtain the consent of the concerned person for the processing of personal data, Art. 6 para. 1 lit. (a) EU General Data Protection Regulation (GDPR) will serve as legal basis.',
        'In the processing of personal data necessary for the fulfillment of a contract to which the concerned person is a party, Art. 6 para. 1 lit. (b) GDPR will serve as legal basis. This also applies to processing operations required to carry out pre-contractual actions.',
        'Insofar as processing of personal data is required to fulfil a legal obligation that is subject to our company, Art. 6 para. 1 lit. (c) GDPR will serve as legal basis.',
        'In the event that vital interests of the concerned person/s or another natural person require the processing of personal data, Art. 6 para. 1 lit. (d) GDPR will serve as legal basis.',
        'If processing is necessary to safeguard the legitimate interests of our company or a third party, and if the interests, fundamental rights and freedoms of the concerned party do not outweigh the interest mentioned first, Art. 6 para. 1 lit. (f) GDPR will serve as legal basis for processing.',
      ]
    },
    {
      primary: 'The object of data protection',
      content: [
        'The object of data protection is personal data. According to Art. 4 No. 1 GDPR, these are all information relating to an identified or identifiable natural person; this includes, for example, names or identification numbers.'
      ]
    },
    {
      primary: 'Collection of web server log files',
      tertiary: 'Description and purpose',
      content: [
        'When accessing our website, our system automatically saves various data and information from the computer system of the calling computer. The storage of the following data together with other personal data of the user does not take place.',
        `These are the following data:
        <ul>
          <li>Internet browser: type, version and functionality (e.g. cookies, Java, Flash, PDF, etc.)</li>
          <li>Operating system, used terminal device and screen resolution</li>
          <li>IP address of the user</li>
          <li>Date and time of the visit to the website</li>
          <li>Website referrer (when linking through another website)</li>
          <li>Outbound links of our website</li>
          <li>Statistical data of the website visit (frequency, duration, URLs, country of origin of the visitor)</li>
        </ul>`,
        `The purpose of the data collection is:
        <ul>
          <li>To enable the delivery of the website to the computer of the website visitor</li>
          <li>Ensuring and optimising the functionality of the website</li>
          <li>Ensuring the safety of the IT infrastructure</li>
         </ul>`
      ]
    },
    {
      tertiary: 'Legal basis',
      content: [
        'This collection of data is carried out on the basis of our legitimate interest in a safe and error-free operation of our IT infrastructure, the fight against abuse, the prosecution of criminal offences and the securing, assertion and enforcement of claims, Art. 6 para. 1 lit. (f) GDPR.'
      ]
    },
    {
      tertiary: 'Duration',
      content: [
        'The stored data will be irretrievably deleted after 14 days. An exceptionally extended storage occurs only if any personal assignment such as IP addresses have been alienated, shortened, or otherwise anonymised. An assignment is thereby no longer possible.'
      ]
    },
    {
      primary: 'Cookies',
      tertiary: 'Description and purpose',
      content: [
        'Our website uses cookies. These are small text files that are stored by the browser on your device. If you do not want to use cookies, you can deactivate the automatic saving in the appropriate settings of your browser.',
        `The following technical cookies are used:
        <ul>
          <li>Cookie notice: To display the cookie notice until confirmed by the user of the website</li>
          <li>Language settings: To save the language settings</li>
          <li>Unit settings: To save the unit settings</li>
         </ul>`,
        'In addition, a cookie of the company Matomo is installed for the statistical registration of the website use, see paragraph below "<strong>Statistics and analysis via Matomo</strong>"'
      ]
    },
    {
      tertiary: 'Legal basis',
      content: [
        'The use of cookies is justified on the basis of our legitimate interest in a needs-based design and the statistical analysis of our website and on the fact that your legitimate interests are not outweighed, Art. 6 para. 1 lit. (f) GDPR.'
      ]
    },
    {
      tertiary: 'Duration and deletion',
      content: [
        'Stored cookies can be deleted at any time on the device. This can, for example, be done automatically using various software solutions. The standardised storage time of cookies is 14 days.'
      ]
    },
    {
      primary: 'Statistics and analysis via Matomo (formerly PIWIK)',
      tertiary: 'Description and purpose',
      content: [
        `Matomo is an open-source software for the statistical evaluation of visitors to a website. Matomo uses cookies that are stored on your device and that allow us to analyse the website (for more information, see the paragraph "<strong>Cookies</strong>" given above). Specifically, the following data is stored:
        <ul>
          <li>Shortened and thus anonymised IP address (last two bytes are deleted)</li>
          <li>Internet browser: type, version and functionality (e.g. cookies, Java, Flash, PDF, etc.)</li>
          <li>Operating system, used terminal device and screen resolution</li>
          <li>Date and time of the visit to the website</li>
          <li>Website referrer (when linking through another website)</li>
          <li>Outbound links of our website</li>
          <li>Statistical data of the website visit (frequency, duration, URLs)</li>
          <li>Geolocation (language and country)</li>
          <li>Downloads</li>
        </ul>`,
        `The following cookies are used for this purpose:
        <ul>
          <li>_pk_ref*: Identification of the country of origin of the visitor. Storage period: 6 months</li>
          <li>_pk_cvar*: Preparation of internal statistics. Storage period: 30 minutes</li>
          <li>_pk_id*: Identification of clear visits. Storage period: 13 months</li>
          <li>_pk_ses*: Session cookie for tracking active sessions. Storage period: 30 minutes</li>
        </ul>`
      ]
    },
    {
      tertiary: 'Legal basis',
      content: [
        'The legal basis of the processing in these cases is Art. 6, para 1, lit. (f) GDPR'
      ]
    },
    {
      tertiary: 'Duration and deletion',
      content: [
        'The stored data will be irrevocably deleted as soon as it is no longer needed for the recording purposes. Here, this is after 3 years.'
      ]
    },
    {
      primary: 'Disclosure of data',
      content: [
        'Unless otherwise stated in the privacy policy, your personal data will be disclosed without your express prior consent only in the following cases:',
        'If it is necessary to investigate the unlawful use of our services or for purposes of prosecution, personal data will be forwarded to law enforcement authorities and, where appropriate, to third parties who have been harmed. However, this only happens if there are concrete indications of unlawful or abusive behaviour. A transfer can also take place if it serves to enforce Terms of Use or other agreements. We are also required by law to provide information to certain public authorities upon request. These are law enforcement agencies, authorities prosecuting administrative offences that involve fines and the tax authorities.',
        'The transfer of this data is based on our legitimate interest in the fight against abuse, the prosecution of criminal offenses and the protection, assertion and enforcement of claims, if in this case the balancing of interests to be carried out by us has revealed that the interests or fundamental rights and fundamental freedoms of the concerned party, which needs the protection of personal data, are not outweighed (Article 6, para 1, lit. (f) GDPR)',
      ]
    },
    {
      primary: 'Your rights as the affected party',
      tertiary: 'Right to information',
      content: [
        `You have the right to request from us any time information about the personal data processed by us in the scope of Art. 15 GDPR by post or e-mail to <a href="mailto:datenschutz@${DataService.domain}">datenschutz@${DataService.domain}</a>. We will then provide you with a copy of the personal data that is the subject of the processing in accordance with Article 15, para 5 GDPR. For this you can submit an application by post or by e-mail to the address given above.`
      ]
    },
    {
      tertiary: 'Right to rectify/complete incorrect data',
      content: [
        'You have the right to request immediate correction or completion of personal data concerning you if it is incorrect. Please contact the above-mentioned addresses.'
      ]
    },
    {
      tertiary: 'Right to delete',
      content: [
        'You have the right to demand the deletion of your personal data under the conditions described in Art. 17 GDPR. In particular, these requirements provide for a right to delete if the personal data are no longer necessary for the purposes for which they were collected or otherwise processed, and in cases of unlawful processing, the right to object or obligation to delete under Union law or the law of the Member State to which we are subject. To assert your right, please contact the below-mentioned addresses.'
      ]
    },
    {
      tertiary: 'Right to restriction of processing',
      content: [
        'You have the right to demand from us that we restrict processing in accordance with Art. 18 GDPR. This right exists in particular if the accuracy of the personal data is disputed between you and us, for the period of time required to verify correctness, and in the case you ask for limited processing instead of deletion in the case of an existing right of deletion; and furthermore in the event that the data is no longer required for the purposes we are pursuing, but which you or us require in order to assert, exercise or defend legal claims, and if the successful exercise of an objection between you and us is still disputed. To assert your right, please contact the below-mentioned addresses.'
      ]
    },
    {
      tertiary: 'Right to data portability',
      content: [
        'You have the right to receive from us the personal data relating to you which you have provided to us in a structured, standard, machine-readable format in accordance with Art. 20 GDPR. To assert your right, please contact the below-mentioned addresses.'
      ]
    },
    {
      tertiary: 'Right to object',
      content: [
        'You have the right at any time, for reasons arising out of your particular situation, to object in accordance with Art. 21 GDPR against the processing of personal data relating to you on the basis of Art. 6 para. 1 lit. (e) or (f) GDPR. We will then stop the processing of your personal information, unless we can demonstrate compelling legitimate grounds for processing that outweigh your interests, rights and freedoms, or the processing is for the purpose of asserting, exercising or defending legal claims.'
      ]
    },
    {
      tertiary: 'Right to lodge complaints to a data protection supervisory authority',
      content: [
        'You have the right to lodge a complaint with our Data Protection Officer (contact details below, at the end of this Privacy Policy) or with a data protection supervisory authority. The contact details of the responsible <strong>data protection supervisory authority</strong> are as follows:',
        'The State Commissioner for Data Protection and Freedom of Information Rhineland-Palatinate<br> Prof. Dr. Dieter Kugelmann<br> Hintere Bleiche 34<br> 55116 Mainz, Germany',
      ]
    },
    {
      primary: 'Change in purpose',
      content: [
        'Processing of your personal data for purposes other than those described will only take place if a legal provision allows this or if you have consented to the revised purpose of the data processing. In the event of further processing for purposes other than those for which the data was originally collected, we will inform you of these other purposes before further processing and provide you with all other relevant information.'
      ]
    },
    {
      primary: 'Deletion of your data',
      content: [
        'We will delete or anonymise your personal information as soon as it is no longer necessary for the purposes for which we collected or used it in accordance with the preceding paragraphs. The data will be irrevocably deleted as far as these data are not subject to statutory retention periods, or are not required for a longer period for prosecuting offences or for securing, asserting or enforcing legal claims. The data is then no longer available for further use.'
      ]
    },
    {
      primary: 'Responsible for data processing',
      content: [
        'SIGMA-ELEKTRO GmbH<br>Dr.-Julius-Leber-Str. 15<br>67433 Neustadt a. d. Weinstra&szlig;e, Germany',
        'Contact details of the Data Protection Officer',
        `You can reach our data protection officer by post at the above address with the addition "Data Protection Officer" or by e-mail at <a href="mailto:datenschutz@${DataService.domain}">datenschutz@${DataService.domain}</a>`,
      ]
    },
  ]
  imprint_paragraphs: Paragraph[] = [
    {
      primary: 'SIGMA-ELEKTRO GmbH',
      content: [
        'Dr.-Julius-Leber-Straße 15',
        '67433 Neustadt',
        'Germany',
        'Phone: <a href="tel:+49632191200">+49(0)6321-9120-0</a>',
        'Fax: +49(0)6321-9120-34',
        `E-mail: <a href="mailto:info@${DataService.domain}">info@${DataService.domain}</a>`,
        `Homepage: <a href="${DataService.company_url}" target="_blank">${DataService.company_url}</a>`,
      ]
    },
    {
      secondary: 'Managing Director:',
      content: [
        'Klaus-Peter Schendel',
        'Robin Schendel',
        'Sven Kageler',
        'Thomas Seifert'
      ]
    },
    {
      secondary: 'Further Informations:',
      content: [
        'Registergericht Ludwigshafen/RH.',
        'Registernummer HRB 42064',
        'UStIDNr. - DE 149391882.'
      ]
    },
    {
      secondary: 'Contacts for the website:',
      content: [
        'Deborah Gyesaw',
        'Phone: <a href="tel:+49632191200">+49(0)6321-9120-0</a>',
        `E-mail: <a href="mailto:info@${DataService.domain}">info@${DataService.domain}</a>`,
      ]
    },
    {
      secondary: 'Data Protection Officer:',
      content: [
        'Responsible for the consultation and implementation of data protection:',
        'Dennis Glas',
        `E-mail: <a href="mailto:datenschutz@${DataService.domain}">datenschutz@${DataService.domain}</a>`,
      ]
    }
  ]
}
