import {Component, Input, ViewEncapsulation} from '@angular/core';
import Chart from '../chart/Chart';
import {Chart as ChartData} from '../../utils/interfaces';
import {ChartForm} from '../../utils/Page';
import {Values} from '../../utils/values';
import {Units} from '../../utils/units';

/**
 * this script is used to create the chart bar logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'column-chart-bar',
  templateUrl: './chart.bar.component.html',
  styleUrls: ['./chart.bar.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ChartBarComponent extends Chart {
  @Input() set chart(data: ChartData){ this.set(data) }


  form(): ChartForm { return ChartForm.bar }

  /**
   * @override add the plotOptions
   * @param chart
   */
  setOptions(chart: Chart) {
    super.setOptions(chart);
    this._options.chart.stacked = true
    this._options.xaxis.crosshairs = this.noShow

    if(this.type === 'assist'){
      /**
       * add the plotOptions: the color configurator
       * @see https://apexcharts.com/docs/options/plotoptions/
       * @see https://apexcharts.com/docs/options/plotoptions/bar/
       * @see https://apexcharts.com/angular-chart-demos/column-charts/column-with-negative-values/ # plotOptions
       */
      const
        labels = this._data.points.labels,
        colors: string[] = this._colors[labels.length] as string[]
      if(colors && colors.length){
        const
          values = this._options.series[0].data,
          ranges: {from: number, to: number, color: string}[] = []

        labels.forEach( (label: number, i: number) => ranges.push({ //labels in raw format; meaning 0 for mode_0, 1 for mode_1
          from: values[i],
          to: values[i],
          color: colors[label] || colors[0]
        }) )
        this._options.stroke = {show: false} //https://apexcharts.com/docs/options/stroke/
        this._options.fill = {}
        this._options.plotOptions = {
          bar: {
            colors: {
              ranges
            }
          }
        }
      }
    }else{
      this.optionsDef.fill.type = 'solid'
      this.setColors()

      const points = this._data.points, typeX = points.typeX
      if(typeX === 'time_x') {
        this._options.xaxis.type = 'category'

        //@see https://apexcharts.com/docs/options/xaxis/
        this._options.xaxis.labels = points.length > 15 ?
          {
            style: {
              fontSize: points.length > 22 ? '.7em' : '12px'
            },
            formatter(value, _, index): string {
              return index === 0 || index === points.length - 1 || value === 6 || value === 12 || value === 18 ?
                `${Values.parse(typeX, value)}${Units.get(typeX)}` :
                ''
            }
          } : {
            formatter(value): string {
              return `${Values.parse(typeX, value)}${Units.get(typeX)}`
            }
          }
      }
    }
  }
}

