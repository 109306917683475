<!--
  this script is used to create the chart bar HTML snippet
  @since PM (18.03.2020)
-->
<div *ngIf="data" class="row mb-5 mt-1 mt-md-2 {{slug}} {{slug}}-{{form()}} {{slug}}-{{identifier}}">
  <div class="d-flex flex-row mb-3">
    <div class="p-2">
      <img src="{{imagesSections + identifier}}.svg" class="section-icon-h">
    </div>
    <div class="p-2">
      <h2 class="line-name font-color mb-3 mb-md-1" [innerHTML]="theo(type)"></h2>
    </div>
  </div>
  <div class="col-12 illustration">
    <apx-chart
      *ngIf="options; else elseBlock"

      [dataLabels]="optionsDef.dataLabels"
      [legend]="noShow"
      [noData]="optionsDef.noData"

      [stroke]="options.stroke || optionsDef.stroke"
      [fill]="options.fill || optionsDef.fill"
      [chart]="options.chart"
      [grid]="options.grid"
      [series]="options.series"
      [title]="options.title"
      [xaxis]="options.xaxis"
      [yaxis]="options.yaxis"
      [tooltip]="options.tooltip"
      [plotOptions]="options.plotOptions || {}"
      [annotations]="options.annotations"
    ></apx-chart>
    <ng-template #elseBlock>
      <div class="loader"></div>
    </ng-template>
  </div>
  <div class="col-12 row mb-2 mt-1 mt-md-2 ml-2 ml-md-2 ml-xl-4">
    <div *ngFor="let key of keys" class="{{col}}">
      <div class="line-info-value">
        <span class="fs-36 font-weight-bold mr-2">{{statistics[key].type | parseValue: statistics[key].value}}</span>
        <span class="fs-14" [innerHTML]="statistics[key].type | getUnit"></span>
      </div>
      <div>
        <span class="info-name fs-14">{{ theo2(key, statistics[key].type) }}</span>
      </div>
    </div>
  </div>
</div>
